// app/javascript/controllers/footer_overlay_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['footerOverlayDiv']

  connect() {
    this.goTopElement = document.getElementById('go_to')
    this.footerElement = document.getElementById('footer')
    this.ctaElement = document.getElementById('fixed_bottom_cta')

    this.currentAdHeight = 0
    this.legacyAdHeight = 0

    window.addEventListener('scroll', this.handleScroll.bind(this))
    this.observer = new MutationObserver(this.observeIframeLoad.bind(this))
    this.observer.observe(this.footerOverlayDivTarget, {
      childList: true,
      subtree: true,
    })
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  close() {
    this.footerOverlayDivTarget.classList.add('!hidden')
    if (this.ctaElement) {
      this.ctaElement.classList.add('!pb-0')
    }

    this.removeGoTopPosition()
    this.removePaddingFooter()
  }

  handleScroll() {
    const scrollPosition = window.scrollY

    if (scrollPosition > 100) {
      this.footerOverlayDivTarget.classList.remove('hidden')
      this.goTopElement.classList.remove('hidden')
    } else {
      this.footerOverlayDivTarget.classList.add('hidden')
      this.goTopElement.classList.add('hidden')
    }
  }

  observeIframeLoad() {
    setInterval(() => {
      const iframe = this.footerOverlayDivTarget.querySelector('iframe')
      const newAdHeight = iframe
        ? parseInt(iframe.height, 10)
        : this.footerOverlayDivTarget.offsetHeight

      if (isNaN(newAdHeight)) {
        // 沒有廣告的狀況
        this.legacyAdHeight = 50
        this.currentAdHeight = this.footerOverlayDivTarget.offsetHeight

        this.addPaddingToFooter()
        this.addGoTopPosition()
      } else if (newAdHeight > this.currentAdHeight) {
        this.legacyAdHeight = this.currentAdHeight
        this.currentAdHeight = newAdHeight

        this.addPaddingToFooter()
        this.addGoTopPosition()
      }
    }, 1000)
  }

  addGoTopPosition() {
    // 解決廣告還沒生成就讀取到 min-h-[50px] 的問題
    const legacyPosition = `bottom-[${
      parseInt(this.legacyAdHeight, 10) + 10
    }px]`
    this.goTopElement.classList.remove(legacyPosition)

    const goTopPosition = `bottom-[${
      parseInt(this.currentAdHeight, 10) + 10
    }px]`
    this.goTopElement.classList.add(goTopPosition)
  }

  removeGoTopPosition() {
    const goTopPosition = `bottom-[${
      parseInt(this.currentAdHeight, 10) + 10
    }px]`
    this.goTopElement.classList.remove(goTopPosition)
  }

  addPaddingToFooter() {
    // 解決廣告還沒生成就讀取到 min-h-[50px] 的問題
    const legacyPaddingClass = `pb-[${parseInt(this.legacyAdHeight + 30)}px]`
    this.footerElement.classList.remove(legacyPaddingClass)
    this.footerElement.classList.remove(`md:${legacyPaddingClass}`)

    const paddingClass = `pb-[${parseInt(this.currentAdHeight, 10) + 30}px]`
    const withCtaPaddingClass = `pb-[${
      parseInt(this.currentAdHeight, 10) + 30 + 30
    }px]`

    if (this.ctaElement) {
      this.footerElement.classList.add(withCtaPaddingClass)
      this.footerElement.classList.add(`md:${withCtaPaddingClass}`)
    } else {
      this.footerElement.classList.add(paddingClass)
      this.footerElement.classList.add(`md:${paddingClass}`)
    }
  }

  removePaddingFooter() {
    const paddingClass = `pb-[${parseInt(this.currentAdHeight, 10) + 30}px]`
    const withCtaPaddingClass = `pb-[${
      parseInt(this.currentAdHeight, 10) + 30 + 30
    }px]`

    if (this.ctaElement) {
      this.footerElement.classList.remove(withCtaPaddingClass)
      this.footerElement.classList.remove(`md:${withCtaPaddingClass}`)
    } else {
      this.footerElement.classList.remove(paddingClass)
      this.footerElement.classList.remove(`md:${paddingClass}`)
    }
  }
}
