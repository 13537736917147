import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'openBtn', 'closeBtn']

  connect() {
    this.openBtnTarget.addEventListener('click', () => this.open())
    this.closeBtnTarget.addEventListener('click', () => this.close())
    this.modalTarget.addEventListener('click', () => this.close())
  }

  open() {
    this.modalTarget.classList.remove('opacity-0')
    this.modalTarget.classList.remove('-z-50')
    this.modalTarget.classList.remove('scale-125')
    this.modalTarget.classList.add('z-[999999]')
    this.modalTarget.classList.add('scale-100')
  }

  close() {
    this.modalTarget.classList.remove('scale-100')
    this.modalTarget.classList.remove('z-[999999]')
    this.modalTarget.classList.add('opacity-0')
    this.modalTarget.classList.add('-z-50')
    this.modalTarget.classList.add('scale-125')
  }
}
